import React from 'react';
import { Link } from 'gatsby';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { HOME_PATH, BLOG_PATH, isActivePath } from '../../util/locations';
import { styled } from '@mui/system';
import { noSelect } from '../../styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  boxShadow: '0px 0px 60px -15px rgba(0,0,0,0.75)',
  padding: '1.1rem',
  background: theme.palette.background.main,
  marginTop: '1rem',
  marginBottom: '1.5rem',
  borderRadius: 1,
}));

const StyledLink = styled(Link, {
  shouldForwardProp(propName) {
    return propName !== 'active';
  },
})(({ theme, active }) => ({
  textDecoration: 'none',
  color: active ? theme.palette.success.light : theme.palette.primary.light,
}));

const Navigation = ({ location }) => {
  const isActive = (path) => isActivePath(location.pathname ?? '/', path);

  return (
    <StyledContainer>
      <Typography
        fontFamily={'Jetbrains mono'}
        justifySelf="center"
        alignSelf="center"
        fontWeight={600}
        mr={1}
        sx={(theme) => ({ color: theme.palette.secondary.accent, ...noSelect })}
      >
        [ frain ]
      </Typography>
      <Box>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledLink active={isActive(HOME_PATH)} to={HOME_PATH}>
            <Typography fontWeight={300} variant="h5">
              Home
            </Typography>
          </StyledLink>
          <StyledLink active={isActive(BLOG_PATH)} to={BLOG_PATH}>
            <Typography fontWeight={300} variant="h5">
              Blog
            </Typography>
          </StyledLink>
        </Breadcrumbs>
      </Box>
    </StyledContainer>
  );
};

export default Navigation;
