import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import theme from '../../src/theme';
import ApplicationContainer from '../../src/components/application-container';
import Squiggly from '../../src/components/squiggly';
import Footer from '../../src/components/footer';
import ApplicationWrapper from '../../src/components/application-wrapper';

export default function TopLayout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApplicationWrapper>
        {children}
        <ApplicationContainer>
          <Squiggly />
        </ApplicationContainer>
        <Footer />
      </ApplicationWrapper>
    </ThemeProvider>
  );
}
