import React from 'react';
import Grid from '@mui/material/Grid';

const Layout = ({ children }) => (
  <Grid container justifyContent={'center'}>
    <Grid item xs={11} md={8} lg={7} xl={7}>
      {children}
    </Grid>
  </Grid>
);

export default Layout;
