import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Grid from '@mui/material/Grid';
import MailIcon from '@mui/icons-material/Mail';
import Link from '@mui/material/Link';
import { styled } from '@mui/system';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.warning.main,
  textDecoration: 'none',
}));

const Footer = () => {
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      container
      position={'absolute'}
      bottom={0}
      height={'2rem'}
      width={'100%'}
    >
      <Grid item ml={'0.5rem'} mr={'0.5rem'}>
        <StyledLink
          aria-label={'Go to my linkedIn page'}
          aria-describedby={'Go to my linkedIn page'}
          href={'https://www.linkedin.com/in/philip-englund-48475a134/'}
        >
          <LinkedInIcon />
        </StyledLink>
      </Grid>
      <Grid item ml={'0.5rem'} mr={'0.5rem'}>
        <StyledLink
          aria-label={'Send me an email'}
          aria-describedby={'Send me an email'}
          href={'mailto:frainbreese@gmail.com'}
        >
          <MailIcon />
        </StyledLink>
      </Grid>
    </Grid>
  );
};

export default Footer;
