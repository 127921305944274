export const noSelect = {
  WebkitTouchCallout: 'none' /* iOS Safari */,
  WebkitUserSelect: 'none' /* Safari */,
  khtmlUserSelect: 'none' /* Konqueror HTML */,
  MozUserSelect: 'none' /* Old versions of Firefox */,
  msUserSelect: 'none' /* Internet Explorer/Edge */,
  userSelect:
    'none ' /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */,
};

export const terminalHeader = {
  content: '""',
  display: 'block',
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  background: '#ff5f55',
  boxShadow: '15px 0 0 0px #ffbd2e, calc(15px * 2) 0 0 0px #25c93f',
};
