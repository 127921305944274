import * as React from 'react';
import TopLayout from './TopLayout';
import NavBar from '../../src/components/navBar';

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <NavBar location={props.location} />
      {element}
    </>
  );
};
