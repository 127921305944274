import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { noSelect } from '../../styles';

const Squiggly = () => {
  return (
    <Grid item>
      <Typography
        style={{ color: '#005D57', fontStyle: 'italic', ...noSelect }}
        align="center"
        component="h1"
        variant="h1"
      >
        ~
      </Typography>
    </Grid>
  );
};

export default Squiggly;
