import React from 'react';

const ApplicationWrapper = ({ children }) => {
  return (
    <div
      id="root"
      style={{
        minHeight: '100vh',
        position: 'relative',
        paddingBottom: '3rem',
      }}
    >
      {children}
    </div>
  );
};

export default ApplicationWrapper;
