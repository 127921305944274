const HOME_PATH = '/';
const BLOG_PATH = '/blog';

export const isActivePath = (pathname, path) => {
  if (path === HOME_PATH) {
    return pathname === path;
  }

  if (pathname.includes(BLOG_PATH)) {
    return true;
  }

  return false;
};

export { HOME_PATH, BLOG_PATH };
