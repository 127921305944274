import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#B1B1B4',
      light: '#FDFDFD',
    },
    secondary: {
      main: '#80C565',
      light: '#E9F6F6',
      accent: '#C93C6C',
    },
    background: {
      default: '#424250',
      main: '#33333D',
      light: '#373740',
      paper: '#424242',
      code: '#22212C',
    },
    error: {
      main: '#DC3545',
      light: '#e974ab',
    },
    warning: {
      main: '#E9624E',
      light: '#FFCF44',
    },
    success: {
      main: '#28A745',
      subtle: '#005D57',
      light: '#37EFBA',
    },
    button: {
      main: '#E9F6F6',
    },
    border: {
      main: '#C93C6C',
    },
  },
  typography: {
    fontSize: 13,

    // FONTS
    fontFamily: [
      'Montserrat',
      'Gotham Pro',
      'sans-serif',
      'JetBrains Mono',
    ].join(','),

    // HEADERS
    h1: {
      fontFamily: 'JetBrains Mono',
    },
    h2: {
      fontFamily: 'JetBrains Mono',
    },
    h3: {
      fontFamily: 'JetBrains Mono',
    },
    h4: {
      fontFamily: 'JetBrains Mono',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'JetBrains Mono',
      fontWeight: 700,
    },
    h6: {
      fontFamily: 'JetBrains Mono',
      fontWeight: 600,
    },
    p: {
      fontFamily: 'Montserrat',
    },
    caption: {
      fontFamily: 'JetBrains Mono',
    },
    textFields: {
      fontWeight: '400',
      color: '#eee',
    },
  },
});
export default theme;
