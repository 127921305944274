import Navigation from '../nav';
import React from 'react';
import Grid from '@mui/material/Grid';

const NavBar = ({ location }) => {
  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={11} md={8} lg={7} xl={7}>
        <Navigation location={location} />
      </Grid>
    </Grid>
  );
};

export default NavBar;
